import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'

import { NButton, NInput } from '~/components'
import { useApi } from '~/hooks'
import { createApp } from '~/services/apps'

import { Container } from './styles'
import { createLicensed } from '~/services/licenseds'

const Authorization = () => {
  const { request } = useApi()

  const navigate = useNavigate()
  const { state } = useLocation()


  const { register, handleSubmit, formState, setError } = useForm({
    resolver: yupResolver(
      object({
        key: string().required('Preencha esse campo!'),
      })
    ),
  })

  const onSubmit = ({ key }) => {
    const formData = new FormData()


    formData.append('name', state.app.name)
    formData.append('domain', state.app.domain)
    formData.append('tax', state.app.tax)
    formData.append('colors[primary]', state.app.colors.primary)
    formData.append('location[state]', state.app.location.state)
    formData.append('location[city]', state.app.location.city)
    formData.append('logo', state.app.logo)


    request(
      () => createApp(formData, key),
      (response) => {
        const { id } = response.data

        request(
          () => createLicensed({ ...state.licensed, appId: id }, key),
          () => navigate (window.location.href ='https://www.weptek.com.br/obrigado')
        )
      },
      () => {
        setError('key', { message: 'Chave inválida ou expirada!' })
      }
    )
  }

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <p className="title">Autorização</p>
      <p className="label">Insira aqui a sua chave de autorização*:</p>
      <NInput
        {...register('key')}
        error={formState.errors.key?.message}
        placeholder="Chave:"
      />
      <p className="instruction">
        *Acabamos de enviar a chave de autorização para o seu email.
      </p>
      <NButton type="submit" label="Enviar" className="button" />
    </Container>
  )
}

export default Authorization
